<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems.sync="tabItems"
      v-model="tab"
      :dense="true"
      align="left"
      :height.sync="tabHeight"
      :splitter="splitter"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="param"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'project-book',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      splitter: { data: 180 },
      tab: 'tab1',
      tabItems: [
        { name: 'tab1', label: '공사안전보건대장', icon: 'foundation', component: () => import(`${'./projectBookStructure.vue'}`) },
        { name: 'tab2', label: '기본/설계 안전보건대장', icon: 'upload_file', component: () => import(`${'./projectBookAttach.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = window.innerHeight;
      numHeight = numHeight - 120
      return numHeight + 'px';
    },
  },
  methods: {
    init() {
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
    },
  }
};
</script>